/* src/FileUpload.css */
body,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Noto Sans", sans-serif; /* Updated font */
  background-color: #e0f7fa; /* Light cyan background */
}

.main-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center; /* Change to row to keep form on the right and SVGs on the left */
  min-height: 100vh; /* Change from fixed height to min-height */
  width: 100%; /* Full width */
  background-color: #f8f9fb; /* Light cyan background for the entire page */
  /* Replace with the correct path to your image */
  background-size: cover; /* Ensures the image covers the container */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  overflow: auto; /* Enable scrolling */
  animation: fadeIn 1s ease-in-out; /* Fade-in animation */
  flex-wrap: nowrap; /* Prevent wrapping */
  position: relative; /* Add position relative */
  gap: 2rem; /* Add gap between elements */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.image-container {
  width: 40%; /* Adjust width for responsiveness */
  min-width: 300px; /* Add minimum width */
  max-width: 800px; /* Add maximum width */
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-right: 10px; /* Add margin to separate from text-container */
  animation: slideInLeft 1s ease-in-out; /* Slide-in animation */
  flex-shrink: 0; /* Prevent shrinking */
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

/* Specific styling for the main image */
.main-image {
  position: relative;
  left: -43%;
  top: -20%;
  width: 140%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Ensure no overflow */
}

.image-container .caption-top {
  font-size: 24px;
  margin-bottom: 15px;
  color: #333;
  font-weight: bold; /* Bold to emphasize the heading */
}
.svgicon {
  position: absolute;
  top: 5%;
  left: 2%;
  padding-left: 28px;
  z-index: 100;
}
.header {
  font-size: 2em;
  color: #008080;
}
/* Container for the certification logos */
.certification-container {
  
  display: flex;
  flex-direction: row;
  justify-content: center; /* Center the logos horizontally */
  align-items: center; /* Center the logos vertically */
  /* Space above the certification container */
  flex-wrap: wrap; /* Allow wrapping if necessary */
  gap: 1rem; /* Space between certification logos */
}

/* Specific styling for the certification logos */
.certification-logo {
  width: auto; /* Adjust the size as needed while maintaining aspect ratio */
  height: 40px; /* Set a consistent height for all logos */
  margin: 0 10px; /* Space between the logos */
  transition: filter 0.3s ease; /* Adds a smooth transition effect */
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

/* Styling for the authentication container */
.authentication {
  display: flex;
  width: 100%; /* Adjust the width for conformity */
  padding: 0;
  align-items: center;
  gap: 8px; /* Increased spacing between image and text for readability */
  border-radius: 4px;
  background: var(--Surface-Secondary, #fff); /* Fallback color */
  color: #000;
  font-family: "Noto Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 0px; /* Space above the authentication message */
}

/* Optionally style the image inside the container */
.authentication img {
  width: auto; /* Maintain aspect ratio */
  height: 15px;
}

.image-container img {
  max-width: 80%; /* Limit the width for responsive design */
  height: auto;
}

.image-container .caption-bottom {
  font-size: 18px;
  margin-top: 15px;
  color: #666;
  max-width: 80%;
}

.text-container {
  width: 28rem;
  max-width: 28rem;
  padding: 1.5rem;
  margin: 0 auto;
  background-color: #FFFFFF;
  border-radius: 0.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: auto; /* Change from fixed height */
  min-height: 90vh; /* Add minimum height */
  overflow-y: scroll;
  /* Hide vertical scrolling */
  margin-bottom: 20px; /* Add margin to separate from other elements */
  animation: slideInRight 1s ease-in-out; /* Slide-in animation */
  flex-shrink: 0; /* Prevent shrinking */
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.text-container h2 {
  font-size: 25px; /* Adjusted to fit better within container */
  margin-bottom: 0;
  font-family: "Noto Sans", sans-serif; /* Updated font */
  color: #0A8394; /* Teal color */
  text-align: center;
}

.text-container form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: fadeIn 1.5s ease-in-out; /* Fade-in animation */
}

.text-container label {
  display: block;
  font-size: 0.875rem;
  color: #4B5563;
  margin-bottom: 0.5rem;
}

.text-container input[type="file"],
.text-container input[type="text"],
input[type="email"], 
input[type="password"] {
  width: 100%; /* Limits width for conformity */
  padding: 0.5rem 1rem;
  border: 1px solid #D1D5DB;
  border-radius: 0.375rem;
  outline: none;
  box-sizing: border-box;
  font-size: 0.875rem;
  color: #4B5563;
  cursor: pointer;
  transition: border-color 0.3s ease; /* Smooth transition for border color */
}

.text-container input[type="file"]:focus,
.text-container input[type="text"]:focus {
  border-color: #0A8394; /* Teal border on focus */
}

.text-container .drag-drop-box {
  width: 100%; /* Full width for responsiveness */
  height: auto; /* Allow height to adjust */
  min-height: 100px; /* Add minimum height */
  margin: 0 0;
  border: 1px solid #D1D5DB;
  border-radius: 0.375rem;
  text-align: center;
  display: flex;
  align-items: center; /* Center text vertically */
  justify-content: center; /* Center text horizontally */
  cursor: pointer;
  color: #666;
  flex-direction: column; /* Allow text and icon to stack */
  transition: background-color 0.3s ease, border-color 0.3s ease; /* Smooth transition for background and border color */
  padding: 1rem;
}

.text-container .drag-drop-box:hover {
  background-color: #b2dfdb; /* Light teal background on hover */
  border-color: #0A8394; /* Teal border on hover */
}

.drag-drop-box.drag-over {
  border-color: #0A8394; /* Change border color on drag over */
  background-color: #f0f0f0; /* Light grey background on drag over */
}

.selected-files {
  /* Ensure a minimum height */
  padding-left: 0%;
  max-height: 30vh; /* Use viewport height instead of fixed height */
  min-height: 50px; /* Add minimum height */
  overflow-y: scroll;
  margin-bottom: 0;
  border: #ccc 1px solid;
  border-radius: 3px;
  width: 100%; /* Full width for responsiveness */
  margin-top: 10px;
  /* Enable vertical scrolling */
}

.selected-files li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  /* border: 0.5px solid #008080;
  border-radius: 4px; */
  margin-bottom: 5px;
  color: #666;
  background-color: #fff;
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.selected-files li:hover {
  background-color: #e0f2f1; /* Light teal background on hover */
}

.selected-files li .remove-icon {
  cursor: pointer;
  color: #0A8394; /* Red color for remove icon */
}

.text-container button {
  width: 100%; /* Full width button */
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  background-color: #0A8394; /* Bright blue color */
  color: white;
  border: none;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition for background color and transform */
  /* Space above the button */
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-shadow: none;
}

/* Add this to ensure no shadow on hover/focus */
.text-container button:hover,
.text-container button:focus {
  box-shadow: none;
  outline: none;
  background-color: #086674;
}

/* Reset button element */
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none !important;
}

.text-container p {
  margin-top: 10px;
  color: #666;
  font-size: 14px;
  text-align: left; /* Left align text */
  width: 100%; /* Full width for responsiveness */
  font-family: "Noto Sans", sans-serif; /* Updated font */
}

.text-container .file-count {
  margin-top: 10px;
  color: #333;
  font-size: 14px;
  text-align: center; /* Center align text */
  width: 100%; /* Full width for responsiveness */
  font-family: "Noto Sans", sans-serif; /* Updated font */
}

.text-container a {
  color: #0A8394;
  text-decoration: none;
}

.text-container a:hover {
  text-decoration: underline;
}

.logout-container {
  position: absolute;
  top: 10px;
  right: 10px;
}

.logout-button {
  background-color: #0A8394;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition for background color and transform */
}

.logout-button:hover {
  background-color: #004d40; /* Darker teal on hover */
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.custom-file-upload {
  display: inline-block;
  padding: 10px 20px;
  cursor: pointer;
  background-color: rgb(221, 221, 221); /* Match the button color */
  color: white;
  border: none;
  border-radius: 4px; /* Rounded corners */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition for background color and transform */
  margin-top: 10px; /* Space above the button */
  text-align: center;
}

.custom-file-upload:hover {
  background-color: #004d40; /* Darker teal on hover */
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.info-icon {
  display: flex;
  align-items: center;
  margin-top: 5px;
  color: #0A8394;
  font-size: 14px;
  animation: fadeIn 2s ease-in-out; /* Fade-in animation */
}

.checkbox-container {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center; /* Center the checkbox horizontally */
  width: 100%; /* Full width for responsiveness */
  padding: 2px; /* Add padding for better spacing */
  /* Light background color */
  /* Rounded corners */
  animation: fadeIn 2.5s ease-in-out; /* Fade-in animation */
}

.checkbox-container .ant-checkbox-wrapper {
  display: flex;
  align-items: center;
  font-size: 14px; /* Adjust font size */
  color: #333; /* Text color */
}

.zipping-spinner {
  display: flex;
  align-items: center;
  margin-left: 10px; /* Space between checkbox and spinner */
  color: #0A8394; /* Spinner text color */
  font-size: 14px; /* Adjust font size */
  animation: fadeIn 3s ease-in-out; /* Fade-in animation */
}

.animated-text-container {
  text-align: center;
}

.sub-header {
  font-size: 1.2em;
  color: #444;
}

/* New container for certifications and carousel */
.image-and-certifications {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem; /* Space between certifications and carousel */
  /* You can adjust the gap as needed */
}

/* Add media query for smaller screens */
@media screen and (max-height: 600px) {
  .main-container {
    padding: 1rem;
  }

  .text-container {
    margin: 10px auto;
    padding: 1rem;
    min-height: auto;
  }
}

/* Heading styles to match login */
h1 {
  font-size: 1.25rem;
  font-weight: 400;
  color: #475569;
  margin-bottom: 0.25rem;
}

h6 {
  font-size: 1.7rem;
  font-weight: 400;
  color: #0F172A;
  margin-bottom: 1.5rem;
}

/* Add consistent spacing */
.form-group {
  margin-bottom: 1.5rem;
}

/* Mobile Responsive Styles */
@media screen and (max-width: 768px) {
  .main-container {
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
    height: auto;
  }

  .image-container {
    width: 90%;
    min-width: auto;
    margin: 0 auto; /* Center horizontally */
    padding: 0; /* Remove padding */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .svgicon {
    position: static;
    margin: 1rem auto;
    padding: 0;
    display: block; /* Ensure logo is centered */
  }

  .text-container {
    width: 90%;
    max-width: 100%;
    margin: 0 auto; /* Center horizontally */
    padding: 1rem;
  }

  .drag-drop-box {
    padding: 1.5rem;
  }

  .certification-container {
    margin-top: 1rem;
    width: 100%;
    justify-content: center;
  }

  h1 {
    font-size: 1.125rem; /* Reduced from 1.25rem */
  }

  h6 {
    font-size: 1.5rem; /* Reduced from 1.7rem */
  }

  .text-container label {
    font-size: 0.813rem; /* Reduced from 0.875rem */
  }

  .text-container input[type="file"],
  .text-container input[type="text"],
  input[type="email"],
  input[type="password"] {
    font-size: 0.813rem;
  }

  .drag-drop-box span {
    font-size: 0.813rem;
  }

  .file-count {
    font-size: 0.813rem;
  }

  button {
    font-size: 0.813rem;
  }
}

@media screen and (max-width: 480px) {
  .text-container form {
    padding: 0.5rem;
  }

  .text-container input[type="file"],
  .text-container input[type="text"] {
    padding: 0.5rem;
    font-size: 0.875rem;
  }

  .selected-files {
    max-height: 25vh;
  }

  .checkbox-container {
    padding: 0.5rem;
  }

  h1 {
    font-size: 1rem;
  }

  h6 {
    font-size: 1.25rem;
  }

  .text-container label {
    font-size: 0.75rem;
  }

  .text-container input[type="file"],
  .text-container input[type="text"],
  input[type="email"],
  input[type="password"] {
    font-size: 0.75rem;
  }

  .drag-drop-box span {
    font-size: 0.75rem;
  }

  .selected-files li span {
    font-size: 0.75rem;
  }

  button {
    font-size: 0.75rem;
  }
}

/* Small height screens */
@media screen and (max-height: 600px) {
  .main-container {
    height: auto;
    min-height: 100vh;
  }

  .text-container {
    margin: 1rem 0;
  }
}

/* Touch device optimizations */
@media (hover: none) {
  .text-container button,
  .drag-drop-box,
  input[type="checkbox"] {
    min-height: 44px; /* Minimum touch target size */
  }

  .selected-files li {
    padding: 0.75rem;
  }

  .remove-icon {
    padding: 0.5rem;
  }
}

/* Landscape orientation */
@media screen and (max-width: 900px) and (orientation: landscape) {
  .main-container {
    flex-direction: row;
    gap: 1rem;
  }

  .image-container {
    width: 45%;
  }

  .text-container {
    width: 45%;
  }

  .certification-container {
    margin-top: 0.5rem;
  }
}

/* High DPI screens */
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .certification-logo {
    image-rendering: -webkit-optimize-contrast;
  }
}

/* Foldable devices */
@media (max-width: 320px) {
  .main-container {
    padding: 0.5rem;
  }

  .text-container {
    padding: 0.75rem;
  }

  .drag-drop-box {
    padding: 1rem;
  }

  h1 {
    font-size: 0.875rem;
  }

  h6 {
    font-size: 1.125rem;
  }

  .text-container label,
  .text-container input,
  .drag-drop-box span,
  button {
    font-size: 0.688rem;
  }
}
