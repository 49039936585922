.carousel-container {

    width: 100%;
    position: relative;
}

.carousel-slide {
    
    position: relative;
    text-align: center;  /* Changed from left to center */
    padding-bottom: 20px;
}

.slide-image {
    width: 405px !important;  /* Force width */
    height: 200px !important; /* Force height */
    border-radius: 8px;
    margin: 0 auto;  /* Center the image */
    display: block;  /* Ensure block display for margin auto to work */
}

.slide-content {
    margin-top: 20px;
    padding-left: 10px;
    text-align: center;  /* Center the content */
    width: 80%;         /* Limit width to allow space for arrows */
    margin-left: auto;  /* Center horizontally */
    margin-right: auto;
}

.slide-title {
    font-size: 24px;
    font-weight: bold;
    color: #0A8394; /* Matches the title color in the image */
    margin-bottom: 10px;
}

.slide-points {
    list-style-type: none; /* No default bullets */
    padding-left: 0;
    display: inline-block;  /* Allow list to be centered */
    text-align: left;      /* Keep list items left-aligned */
}

.slide-points li {
    font-size: 16px;
    color: #333; /* Matches the text color in the image */
    line-height: 1.5;
    margin-bottom: 8px;

    /* Custom bullet styling */
    position: relative;
    padding-left: 20px; /* Space for bullet */
}

.slide-points li::before {
    content: "•"; /* Custom bullet */
    position: absolute;
    left: 0; /* Align bullet to left */
    color: #0A8394; /* Matches the teal color in the image */
    font-size: 18px; /* Slightly larger bullet */
}

/* Custom navigation dots styling */
.custom-dots {
    display: flex !important;
    justify-content: center;
}

/* Adjust gap between navigation dots */
.custom-dots li {
    margin: 0 3px; /* Further reduced spacing */
}

/* Increase size of navigation dots with enhanced specificity */
.custom-dots li button::before {
    font-size: 12px !important; /* Increased from 14px */
    width: 12px; /* Added to increase dot width */
    height: 12px; /* Added to increase dot height */
    line-height: 12px; /* Aligns the dot vertically */
}

.custom-dots li.slick-active button::before {
    color: #0A8394 !important; /* Active dot color matches teal (#0A8394) */
}
